<template>
	<div class="wrap">
		<div class="flex justify-end">
			<!-- 操作按钮 -->
			<el-form :inline="true">
				<el-form-item>
					<el-button @click="toEdit()">新增技师</el-button>
					<el-button icon="el-icon-menu" @click="dialogVisible = true">技师端二维码</el-button>
				</el-form-item>
			</el-form>
		</div>

		<el-table :data="tableData" v-loading="loading" max-height="9999">
			<el-table-column type="index" width="50"></el-table-column>

			<el-table-column prop="name" label="帐号" width="200"></el-table-column>
			<el-table-column prop="nickname" label="姓名" width="150"></el-table-column>

			<el-table-column prop="level" label="等级">
				<template slot-scope="scope">
					<div v-if="scope.row.level==1">初级</div>
					<div v-if="scope.row.level==2">中级</div>
					<div v-if="scope.row.level==3">高级</div>
				</template>
			</el-table-column>

			<el-table-column fixed="right" label="操作" width="150">
				<template slot-scope="scope">
					<el-button size="mini" @click="toEdit(scope.row)">编辑</el-button>
					<el-popconfirm title="确定删除吗？" @confirm="del(scope.row)">
						<el-button slot="reference" size="mini">删除</el-button>
					</el-popconfirm>
				</template>
			</el-table-column>
		</el-table>
		<el-dialog title="技师端二维码" :visible.sync="dialogVisible" append-to-body width="600px">
			<div class="flex flex-direction align-center">
				<el-image style="width: 300px; height: 300px" :src="require('../../assets/js.png')"></el-image>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="dialogVisible = false">关闭</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				dialogVisible: false,
				loading: false,
				tableData: []
			};
		},
		mounted() {
			this.loadData();
		},
		methods: {
			toEdit(user) {
				if (user) {
					// 编辑
					this.$router.push(`/staff/edit/${user.id}`);
				} else {
					// 新增
					this.$router.push("/staff/edit");
				}
			},
			loadData() {
				this.loading = true;
				this.$api.staff.getStaffs().then(res => {
					this.loading = false;
					this.tableData = res.data.data;
				});
			},
			del(item) {
				const loading = this.$loading()
				this.$api.staff.del({
					id: item.id
				}).then(res => {
					loading.close()
					if (res.code == 200) {
						// 保存成功
						this.$message.success(`操作成功`);
						this.loadData()
					} else {
						this.$message.error(res.msg);
					}
				}).catch(err => {
					loading.close()
					this.$alert(err);
				});
			}
		}
	};
</script>
<style scoped lang="scss">

</style>
